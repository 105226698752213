import MicroModal from '../vendor/micromodal.js';
import {lockScroll, unlockScroll} from '../utils/utils.js';

export const createMap = (isMobile) => {
  const objectElement = document.querySelector(`.map__image`);
  const mapPath = isMobile ? `img/map/map-mobile.svg` : `img/map/map.svg`;
  objectElement.setAttribute(`data`, mapPath);
};

export const createMapEvents = (isMobile) => {
  const svgObject = document.querySelector(`.map__image`).contentDocument;
  const svg = svgObject.querySelector(`svg`);
  const groups = svg.querySelectorAll(`.house-group`);

  groups.forEach((group) => {

    if (!isMobile) {
      const houseHover = group.querySelector(`.house-hover`);
      const houseStatic = group.querySelector(`.house-static`);

      group.style.cursor = `pointer`;
      houseHover.style.visibility = `hidden`;

      group.addEventListener(`mouseover`, () => {
        houseHover.style.visibility = `visible`;
        houseStatic.style.visibility = `hidden`;
      });

      group.addEventListener(`mouseleave`, () => {
        houseStatic.style.visibility = `visible`;
        houseHover.style.visibility = `hidden`;
      });
    }

    group.addEventListener(`click`, () => {
      MicroModal.show(group.id, {
        disableScroll: true,
        disableFocus: false,
        onShow: () => lockScroll(),
        onClose: (modal, element, event) => {
          event.preventDefault();
          event.stopPropagation();

          unlockScroll();
        },
      });
    });
  });

  document.querySelector(`.map`).style.opacity = 1;
};
