import setupValues, {prepValues} from './modules/values.js';
import {createMap, createMapEvents} from './modules/map.js';
import {ANIMATIONS, createAnimation, destroyAnimation} from './modules/animation.js';
import {WOW} from './vendor/wow.js';
import MicroModal from './vendor/micromodal.js';
import {debounce} from './utils/debounce.js';
import {checkIfMobile} from './utils/utils.js';

new WOW({
  live: false,
}).init();

MicroModal.init({
  disableFocus: true,
});

let activeAnimation = {};
let isMobile = checkIfMobile();

createMap(isMobile);

const VALUES_ANIMATION_CLASSES = [`animate__animated`, `animate__fadeIn`];
const historyElement = document.querySelector(`.history`);
const historyBlockElements = Array.from(historyElement.querySelectorAll(`.history__block`));
const listWithImages = document.querySelector(`.facts__list-with-images`);
const listWithNumbers = document.querySelector(`.facts__list-with-numbers`);
const mainSection = document.querySelector(`.main-screen`);
const missionButton = document.querySelector(`.mission__button`);
const mapSection = document.querySelector(`.map`);
const mapObject = document.querySelector(`.map__image`);

const animationObserver = new IntersectionObserver((entries) => {
  entries.forEach(({isIntersecting, target}) => {
    const sectionName = target.id.toUpperCase();

    if (isIntersecting) {
      activeAnimation[sectionName] = createAnimation(ANIMATIONS[sectionName], true);
    } else if (activeAnimation[sectionName]) {
      destroyAnimation(activeAnimation[sectionName]);
    }
  });
});

animationObserver.observe(mapSection);
animationObserver.observe(mainSection);

listWithImages.querySelector(`li:last-of-type p`).addEventListener(`animationend`, () => {
  const listElements = Array.from(listWithNumbers.querySelectorAll(`li`));

  listElements.forEach((element) => {
    element.classList.add(`animate__slideInUp`);
    element.classList.remove(`facts__hidden`);
  });
});

const scrollHandler = debounce(() => {
  historyBlockElements.forEach((historyBlockElement, index) => {
    let heightToElement = historyBlockElement.offsetTop - window.pageYOffset;
    const elementHeight = historyBlockElement.offsetHeight;

    if (window.innerHeight - heightToElement - elementHeight > 0) {
      historyBlockElement.classList.add(`history-animation--${index + 1}`);
      historyBlockElement.addEventListener(`animationend`, () => {
        historyBlockElement.classList.remove(`history__block--no-background-lines`);
      });
    }
  });
});

window.addEventListener(`scroll`, scrollHandler);
mapObject.addEventListener(`load`, () => createMapEvents(isMobile));
missionButton.addEventListener(`click`, () => {
  destroyAnimation(activeAnimation.MAP);

  prepValues();
  setupValues();

  MicroModal.show(`values-modal`, {
    disableScroll: true,
    onShow: (modal) => modal.classList.add(...VALUES_ANIMATION_CLASSES),
    onClose: () => location.reload(),
  });
});

const resizeHandler = () => {
  const isMobileWidth = checkIfMobile();

  if (isMobile === isMobileWidth) {
    return;
  }

  isMobile = isMobileWidth;
  createMap(isMobileWidth);
};

window.addEventListener(`orientationchange`, resizeHandler);
window.addEventListener(`resize`, debounce(resizeHandler));

