export const imagesDataDesktop = {
  0: [
    {
      className: `values__letter values__letter--1 hidden`,
      src: {
        png: `img/values/letter-1@1x.png`,
        webp: `img/values/letter-1@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-1@2x.png`,
        webp: `img/values/letter-1@2x.webp`,
      },
      width: 65,
      height: 67,
      alt: `Буква «М»`,
    },
    {
      className: `values__letter values__letter--2 hidden`,
      src: {
        png: `img/values/letter-2@1x.png`,
        webp: `img/values/letter-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-2@2x.png`,
        webp: `img/values/letter-2@2x.webp`,
      },
      width: 60,
      height: 63,
      alt: `Буква «И»`,
    },
    {
      className: `values__letter values__letter--3 hidden`,
      src: {
        png: `img/values/letter-3@1x.png`,
        webp: `img/values/letter-3@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-3@2x.png`,
        webp: `img/values/letter-3@2x.webp`,
      },
      width: 51,
      height: 53,
      alt: `Буква «С»`,
    },
    {
      className: `values__letter values__letter--4 hidden`,
      src: {
        png: `img/values/letter-4@1x.png`,
        webp: `img/values/letter-4@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-4@2x.png`,
        webp: `img/values/letter-4@2x.webp`,
      },
      width: 52,
      height: 53,
      alt: `Буква «С»`,
    },
    {
      className: `values__letter values__letter--5 hidden`,
      src: {
        png: `img/values/letter-5@1x.png`,
        webp: `img/values/letter-5@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-5@2x.png`,
        webp: `img/values/letter-5@2x.webp`,
      },
      width: 49,
      height: 64,
      alt: `Буква «И»`,
    },
    {
      className: `values__letter values__letter--6 hidden`,
      src: {
        png: `img/values/letter-6@1x.png`,
        webp: `img/values/letter-6@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-6@2x.png`,
        webp: `img/values/letter-6@2x.webp`,
      },
      width: 58,
      height: 57,
      alt: `Буква «Я»`,
    },
    {
      className: `values__item values__item--f1-i1 hidden`,
      src: {
        png: `img/values/box-1@1x.png`,
        webp: `img/values/box-1@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-1@2x.png`,
        webp: `img/values/box-1@2x.webp`,
      },
      width: 60,
      height: 104,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i2 hidden`,
      src: {
        png: `img/values/box-2@1x.png`,
        webp: `img/values/box-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-2@2x.png`,
        webp: `img/values/box-2@2x.webp`,
      },
      width: 60,
      height: 69,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i3 hidden`,
      src: {
        png: `img/values/box-3@1x.png`,
        webp: `img/values/box-3@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-3@2x.png`,
        webp: `img/values/box-3@2x.webp`,
      },
      width: 104,
      height: 120,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i4 hidden`,
      src: {
        png: `img/values/plant-1@1x.png`,
        webp: `img/values/plant-1@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-1@2x.png`,
        webp: `img/values/plant-1@2x.webp`,
      },
      width: 20,
      height: 38,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i5 hidden`,
      src: {
        png: `img/values/box-4@1x.png`,
        webp: `img/values/box-4@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-4@2x.png`,
        webp: `img/values/box-4@2x.webp`,
      },
      width: 60,
      height: 71,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i6 hidden`,
      src: {
        png: `img/values/box-5@1x.png`,
        webp: `img/values/box-5@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-5@2x.png`,
        webp: `img/values/box-5@2x.webp`,
      },
      width: 60,
      height: 105,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i7 hidden`,
      src: {
        png: `img/values/box-6@1x.png`,
        webp: `img/values/box-6@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-6@2x.png`,
        webp: `img/values/box-6@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i8 hidden`,
      src: {
        png: `img/values/plant-2@1x.png`,
        webp: `img/values/plant-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-2@2x.png`,
        webp: `img/values/plant-2@2x.webp`,
      },
      width: 20,
      height: 38,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i9 hidden`,
      src: {
        png: `img/values/box-2@1x.png`,
        webp: `img/values/box-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-2@2x.png`,
        webp: `img/values/box-2@2x.webp`,
      },
      width: 60,
      height: 69,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i10 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i11 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i12 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i13 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i14 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f1-i15 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
  ],
  1: [
    {
      className: `values__item values__item--f2-i1 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i2 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i3 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i4 hidden`,
      src: {
        png: `img/values/box-8@1x.png`,
        webp: `img/values/box-8@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-8@2x.png`,
        webp: `img/values/box-8@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i5 hidden`,
      src: {
        png: `img/values/box-6@1x.png`,
        webp: `img/values/box-6@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-6@2x.png`,
        webp: `img/values/box-6@2x.webp`,
      },
      width: 60,
      height: 48,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i6 hidden`,
      src: {
        png: `img/values/box-4@1x.png`,
        webp: `img/values/box-4@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-4@2x.png`,
        webp: `img/values/box-4@2x.webp`,
      },
      width: 60,
      height: 71,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i7 hidden`,
      src: {
        png: `img/values/plant-2@1x.png`,
        webp: `img/values/plant-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-2@2x.png`,
        webp: `img/values/plant-2@2x.webp`,
      },
      width: 20,
      height: 38,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i8 hidden`,
      src: {
        png: `img/values/plant-2@1x.png`,
        webp: `img/values/plant-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-2@2x.png`,
        webp: `img/values/plant-2@2x.webp`,
      },
      width: 20,
      height: 38,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i9 hidden`,
      src: {
        png: `img/values/value-1@1x.png`,
        webp: `img/values/value-1@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-1@2x.png`,
        webp: `img/values/value-1@2x.webp`,
      },
      width: 93,
      height: 87,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i10 hidden`,
      src: {
        png: `img/values/value-2@1x.png`,
        webp: `img/values/value-2@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-2@2x.png`,
        webp: `img/values/value-2@2x.webp`,
      },
      width: 83,
      height: 90,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i11 hidden`,
      src: {
        png: `img/values/value-3@1x.png`,
        webp: `img/values/value-3@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-3@2x.png`,
        webp: `img/values/value-3@2x.webp`,
      },
      width: 82,
      height: 92,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i12 hidden`,
      src: {
        png: `img/values/value-4@1x.png`,
        webp: `img/values/value-4@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-4@2x.png`,
        webp: `img/values/value-4@2x.webp`,
      },
      width: 80,
      height: 85,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i13 hidden`,
      src: {
        png: `img/values/value-5@1x.png`,
        webp: `img/values/value-5@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-5@2x.png`,
        webp: `img/values/value-5@2x.webp`,
      },
      width: 66,
      height: 94,
      alt: `Часть здания`,
    },
  ],
  2: [
    {
      className: `values__item values__item--f3-i1 hidden`,
      src: {
        png: `img/values/box-9@1x.png`,
        webp: `img/values/box-9@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-9@2x.png`,
        webp: `img/values/box-9@2x.webp`,
      },
      width: 27,
      height: 27,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f3-i2 hidden`,
      src: {
        png: `img/values/plant-3@1x.png`,
        webp: `img/values/plant-3@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-3@2x.png`,
        webp: `img/values/plant-3@2x.webp`,
      },
      width: 15,
      height: 21,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f3-i3 hidden`,
      src: {
        png: `img/values/logo-part@1x.png`,
        webp: `img/values/logo-part@1x.webp`,
      },
      srcX2: {
        png: `img/values/logo-part@2x.png`,
        webp: `img/values/logo-part@2x.webp`,
      },
      width: 50,
      height: 36,
      alt: `Часть здания`,
    },
  ],
};

export const imagesDataMobile = {
  0: [
    {
      className: `values__letter values__letter--1 hidden`,
      src: {
        png: `img/values/letter-1-mobile@1x.png`,
        webp: `img/values/letter-1-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-1-mobile@2x.png`,
        webp: `img/values/letter-1-mobile@2x.webp`,
      },
      width: 65,
      height: 67,
      alt: `Буква «М»`,
    },
    {
      className: `values__letter values__letter--2 hidden`,
      src: {
        png: `img/values/letter-2-mobile@1x.png`,
        webp: `img/values/letter-2-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-2-mobile@2x.png`,
        webp: `img/values/letter-2-mobile@2x.webp`,
      },
      width: 60,
      height: 63,
      alt: `Буква «И»`,
    },
    {
      className: `values__letter values__letter--3 hidden`,
      src: {
        png: `img/values/letter-3-mobile@1x.png`,
        webp: `img/values/letter-3-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-3-mobile@2x.png`,
        webp: `img/values/letter-3-mobile@2x.webp`,
      },
      width: 51,
      height: 53,
      alt: `Буква «С»`,
    },
    {
      className: `values__letter values__letter--4 hidden`,
      src: {
        png: `img/values/letter-4-mobile@1x.png`,
        webp: `img/values/letter-4-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-4-mobile@2x.png`,
        webp: `img/values/letter-4-mobile@2x.webp`,
      },
      width: 52,
      height: 53,
      alt: `Буква «С»`,
    },
    {
      className: `values__letter values__letter--5 hidden`,
      src: {
        png: `img/values/letter-5-mobile@1x.png`,
        webp: `img/values/letter-5-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-5-mobile@2x.png`,
        webp: `img/values/letter-5-mobile@2x.webp`,
      },
      width: 49,
      height: 64,
      alt: `Буква «И»`,
    },
    {
      className: `values__letter values__letter--6 hidden`,
      src: {
        png: `img/values/letter-6-mobile@1x.png`,
        webp: `img/values/letter-6-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/letter-6-mobile@2x.png`,
        webp: `img/values/letter-6-mobile@2x.webp`,
      },
      width: 58,
      height: 57,
      alt: `Буква «Я»`,
    }
  ],
  1: [
    {
      className: `values__item values__item--f2-i9 hidden`,
      src: {
        png: `img/values/value-1-mobile@1x.png`,
        webp: `img/values/value-1-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-1-mobile@2x.png`,
        webp: `img/values/value-1-mobile@2x.webp`,
      },
      width: 93,
      height: 87,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i10 hidden`,
      src: {
        png: `img/values/value-2-mobile@1x.png`,
        webp: `img/values/value-2-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-2-mobile@2x.png`,
        webp: `img/values/value-2-mobile@2x.webp`,
      },
      width: 83,
      height: 90,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i11 hidden`,
      src: {
        png: `img/values/value-3-mobile@1x.png`,
        webp: `img/values/value-3-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-3-mobile@2x.png`,
        webp: `img/values/value-3-mobile@2x.webp`,
      },
      width: 82,
      height: 92,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i12 hidden`,
      src: {
        png: `img/values/value-4-mobile@1x.png`,
        webp: `img/values/value-4-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-4-mobile@2x.png`,
        webp: `img/values/value-4-mobile@2x.webp`,
      },
      width: 80,
      height: 85,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f2-i13 hidden`,
      src: {
        png: `img/values/value-5-mobile@1x.png`,
        webp: `img/values/value-5-mobile@1x.webp`,
      },
      srcX2: {
        png: `img/values/value-5-mobile@2x.png`,
        webp: `img/values/value-5-mobile@2x.webp`,
      },
      width: 66,
      height: 94,
      alt: `Часть здания`,
    },
  ],
  2: [
    {
      className: `values__item values__item--f3-i1 hidden`,
      src: {
        png: `img/values/box-9@1x.png`,
        webp: `img/values/box-9@1x.webp`,
      },
      srcX2: {
        png: `img/values/box-9@2x.png`,
        webp: `img/values/box-9@2x.webp`,
      },
      width: 27,
      height: 27,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f3-i2 hidden`,
      src: {
        png: `img/values/plant-3@1x.png`,
        webp: `img/values/plant-3@1x.webp`,
      },
      srcX2: {
        png: `img/values/plant-3@2x.png`,
        webp: `img/values/plant-3@2x.webp`,
      },
      width: 15,
      height: 21,
      alt: `Часть здания`,
    },
    {
      className: `values__item values__item--f3-i3 hidden`,
      src: {
        png: `img/values/logo-part@1x.png`,
        webp: `img/values/logo-part@1x.webp`,
      },
      srcX2: {
        png: `img/values/logo-part@2x.png`,
        webp: `img/values/logo-part@2x.webp`,
      },
      width: 50,
      height: 36,
      alt: `Часть здания`,
    },
  ],
};

