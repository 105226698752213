import lottie from '../vendor/lottie.js';

const ANIMATION_PATH_PREFIX = `animation`;

export const ANIMATIONS = {
  MAIN: [
    {
      path: `01/data.json`,
      containerClass: `.main-screen__animation-item--1`,
    },
    {
      path: `02-05-13-21/data.json`,
      containerClass: `.main-screen__animation-item--2`,
    },
    {
      path: `03-04-11-17/data.json`,
      containerClass: `.main-screen__animation-item--3`,
    },
  ],
  MAP: [
    {
      path: `03-04-11-17/data.json`,
      containerClass: `.map__animation--1`,
    },
    {
      path: `02-05-13-21/data.json`,
      containerClass: `.map__animation--2`,
    },
    {
      path: `car1/data.json`,
      style: `map__car-animation`,
      viewbox: `700 800 300 300`,
      containerClass: `.map__animation--3`,
    },
    {
      path: `06-22/data.json`,
      containerClass: `.map__animation--4`,
    },
    {
      path: `car2/data.json`,
      style: `map__car-animation-2`,
      viewbox: `800 1100 200 300`,
      containerClass: `.map__animation--5`,
    },
    {
      path: `07/data.json`,
      containerClass: `.map__animation--6`,
    },
    {
      path: `car1/data.json`,
      style: `map__car-animation-3`,
      viewbox: `700 800 300 300`,
      containerClass: `.map__animation--7`,
      direction: -1,
    }
  ],
  VALUES: [
    [{
      path: `03-04-11-17/data.json`,
      containerClass: `.values__animation--1`,
    }],
    [{
      path: `12/data.json`,
      containerClass: `.values__animation--2`,
    },
    {
      path: `02-05-13-21/data.json`,
      containerClass: `.values__animation--3`,
    }],
    [{
      path: `16/data.json`,
      containerClass: `.values__animation--4`,
    },
    {
      path: `17/data.json`,
      containerClass: `.values__animation--5`,
    }],
    [{
      path: `14/data.json`,
      containerClass: `.values__animation--6`,
    },
    {
      path: `15/data.json`,
      containerClass: `.values__animation--7`,
    }],
    [{
      path: `03-04-11-17/data.json`,
      containerClass: `.values__animation--8`,
    }],
  ],
  FINAL: [
    {
      path: `18/data.json`,
      containerClass: `.values__animation--9`,
    },
    {
      path: `19/data.json`,
      containerClass: `.values__animation--10`,
    },
    {
      path: `02-05-13-21/data.json`,
      containerClass: `.values__animation--11`,
    },
    {
      path: `06-22/data.json`,
      containerClass: `.values__animation--12`,
    },
  ]
};

export const createAnimation = (animations, autoPlay = false) => {
  return animations.map(({containerClass, style, viewbox, path, direction}) => {
    const container = document.querySelector(containerClass);

    const animation = lottie.loadAnimation({
      container,
      renderer: `svg`,
      loop: true,
      autoplay: autoPlay,
      path: `${ANIMATION_PATH_PREFIX}/${path}`,
      rendererSettings: {
        className: style ? style : ``,
        viewBoxOnly: viewbox ? true : false,
        viewBoxSize: viewbox ? viewbox : ``,
      }
    });

    if (direction) {
      animation.setDirection(direction);
    }

    return animation;
  });
};

export const destroyAnimation = (animations) => {
  animations.forEach((animation) => animation.destroy());
};

export const playAnimation = (animations) => {
  animations.forEach((animation) => animation.play());
};

export const stopAnimation = (animations) => {
  animations.forEach((animation) => animation.stop());
};
