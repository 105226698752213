const MOBILE_VIEWPORT_SIDE = 768;

export const checkIfMobile = () => window.screen.width <= MOBILE_VIEWPORT_SIDE;

export const lockScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.left = `0`;
  document.body.style.right = `0`;
  document.body.style.position = `fixed`;
};

export const unlockScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = ``;
  document.body.style.top = ``;
  document.body.style.left = ``;
  document.body.style.right = ``;
  window.scrollTo(0, parseInt(scrollY || `0`, 10) * -1);
};
